import { Link } from 'react-router-dom';

import './button.scss';

const Button = ({ type, classes, title, leftIcon, rightIcon, onClick }) => {
    const btnClasses = `btn ${classes}`;
    const leftIconClasses = `icon _left ${leftIcon}`;
    const rightIconClasses = `icon _right ${rightIcon}`;
    return (
        <button type={type} className={btnClasses} onClick={onClick}>
            {
                !!leftIcon && <span className={leftIconClasses} aria-hidden="true"></span>
            }
            {title}
            {
                !!rightIcon && <span className={rightIconClasses} aria-hidden="true"></span>
            }
        </button>
    )
}

const LinkButton = ({ href, classes, title, leftIcon, rightIcon }) => {
    const linkClasses = `btn ${classes}`;
    const leftIconClasses = `icon _left ${leftIcon}`;
    const rightIconClasses = `icon _right ${rightIcon}`;
    return (
        <Link to={href} className={linkClasses}>
            {
                !!leftIcon && <span className={leftIconClasses} aria-hidden="true"></span>
            }
            {title}
            {
                !!rightIcon && <span className={rightIconClasses} aria-hidden="true"></span>
            }
        </Link>
    )
}

export { Button, LinkButton };