import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { Select, MenuItem } from '@mui/material';
import { addCuratorToAuditThunk } from '../../store/auditSlice';


const AuditTable = ({ data }) => {
    const users = useSelector((state) => state.users.users);
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const updatedRows = data?.map((item) => ({
            id: item.id,
            name: item.name,
            start_date: item.start_date,
            end_date: item.end_date,
            curator: item.curator?.first_name && item.curator?.last_name 
                ? `${item.curator.first_name} ${item.curator.last_name}` 
                : 'Не выбран',
        })) || [];
        setRows(updatedRows);
    }, [data]);

    const handleCuratorChange = (auditId, selectedUser) => {
        if (selectedUser) {
            dispatch(addCuratorToAuditThunk({ auditId, auditor: selectedUser })).then(() => {
                const updatedData = data.map((item) => {
                    if (item.id === auditId) {
                        return {
                            ...item,
                            curator: selectedUser,
                        };
                    }
                    return item;
                });
                setRows(updatedData.map((item) => ({
                    id: item.id,
                    name: item.name,
                    start_date: item.start_date,
                    end_date: item.end_date,
                    curator: item.curator?.first_name && item.curator?.last_name 
                        ? `${item.curator.first_name} ${item.curator.last_name}` 
                        : 'Не выбран',
                })));
            });
        }
    };

    const columns = [
        { field: 'name', headerName: 'Название', width: 650 },
        { field: 'start_date', headerName: 'Дата начала', width: 150 },
        { field: 'end_date', headerName: 'Дата окончания', width: 150 },
        {
            field: 'curator',
            headerName: 'Ответственный',
            width: 200,
            renderCell: (params) => (
                <Select
                    value={params.value || 'Не выбран'}
                    onChange={(event) => {
                        const selectedUser = users.find(
                            (user) => `${user.first_name} ${user.last_name}` === event.target.value
                        );
                        handleCuratorChange(params.row.id, selectedUser);
                    }}
                    fullWidth
                >
                    <MenuItem value="Не выбран">Не выбран</MenuItem>
                    {users.map((user) => (
                        <MenuItem key={user.id} value={`${user.first_name} ${user.last_name}`}>
                            {user.first_name} {user.last_name}
                        </MenuItem>
                    ))}
                </Select>
            ),
        },
    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
            />
        </div>
    );
};

export default AuditTable;
