import React, { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Box } from '@mui/material';
import { fetchAuditProgram } from '../../store/auditSlice';

const AuditProgramTable = ({ auditId, refreshTrigger }) => {
    const dispatch = useDispatch();
    const programData = useSelector((state) => state.audits.programData);
    const programStatus = useSelector((state) => state.audits.programStatus);

    useEffect(() => {
        if (auditId) {
            dispatch(fetchAuditProgram(auditId));
        }
    }, [auditId, dispatch]);

    // Дополнительный useEffect для обновления при успешной генерации
    useEffect(() => {
        if (refreshTrigger && auditId) {
            dispatch(fetchAuditProgram(auditId));
        }
    }, [refreshTrigger, auditId, dispatch]);

    const columns = [
        {
            field: 'row_index',
            headerName: '№',
            width: 100,
            cellClassName: 'custom-cell',
        },
        {
            field: 'goals',
            headerName: 'Цели',
            flex: 1,
            cellClassName: 'custom-cell',
        },
        {
            field: 'risks',
            headerName: 'Риски',
            flex: 1,
            cellClassName: 'custom-cell',
        },
        {
            field: 'risk_factors',
            headerName: 'Факторы риска',
            flex: 1,
            cellClassName: 'custom-cell',
        },
        {
            field: 'testing_procedures',
            headerName: 'Процедуры тестирования',
            flex: 1,
            cellClassName: 'custom-cell',
        },
    ];

    const rows = programData.map((item) => ({
        id: item.row_index, // Используем row_index как уникальный идентификатор
        ...item,
    }));

    return (
        <Box sx={{ marginBottom: '2rem' }}>
            <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
                Программа аудита
            </Typography>
            <Box sx={{ height: 'auto', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    autoHeight // Автоматическая высота таблицы
                    localeText={{
                        noRowsLabel: 'Значения отсутствуют',
                    }}
                    getRowHeight={() => 'auto'} // Автоматическая высота строк
                    sx={{
                        '& .custom-cell': {
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            lineHeight: '1.5rem',
                        },
                        '& .MuiDataGrid-cell': {
                            alignItems: 'flex-start',
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default AuditProgramTable;
