import "./styles.css"; //импорт стилей, чтобы применить стили к компоненту

import React, { useEffect, useRef } from "react"; //Импортит React-библиотеки и двух хукиов: useEffect и useRef. useEffect,a useRef позволяет сохранять ссылки на DOM-элементы

//функциональный компонент для отображения диаграммы Ганта
const GanttChart = ({audits}) => {
  const tasks = audits.map((audit) => ({
    name: audit.name,
    start: audit.start_date,
    end: audit.end_date,
  }));

  const containerRef = useRef(null);

  const createGanttChart = () => {
    const container = containerRef.current;

    tasks.forEach((task) => {
      const taskElement = document.createElement("div");
      taskElement.className = "task";
      taskElement.style.position = "relative";

      const taskBar = document.createElement("div");
      taskBar.className = "task-bar";

    // Добавляем название задачи внутрь полоски
      const taskName = document.createElement("span");
      taskName.className = "task-name"
      taskName.innerText = task.name;

      taskBar.appendChild(taskName);

      // Логика перетаскивания
      let isDragging = false;
      let offsetX;

      taskBar.addEventListener("mousedown", (e) => {
        isDragging = true;
        offsetX = e.clientX - taskBar.getBoundingClientRect().left;
        e.stopPropagation();
      });

      document.addEventListener("mousemove", (e) => {
        if (isDragging) {
          let newLeft =
            e.clientX - container.getBoundingClientRect().left - offsetX;
          newLeft = Math.max(
            0,
            Math.min(newLeft, container.clientWidth - taskBar.offsetWidth)
          );
          taskBar.style.left = `${newLeft}px`;
        }
      });

      document.addEventListener("mouseup", () => {
        isDragging = false;
      });

      // Логика изменения ширины полоски
      const leftResizeHandle = document.createElement("div");
      leftResizeHandle.className = "resize-handle";
      leftResizeHandle.style.height = "30px";
      leftResizeHandle.style.left = "0";

      const rightResizeHandle = document.createElement("div");
      rightResizeHandle.className = "resize-handle";
      rightResizeHandle.style.height = "30px";
      rightResizeHandle.style.right = "0";

      let isResizingLeft = false;
      let isResizingRight = false;

      leftResizeHandle.addEventListener("mousedown", (e) => {
        isResizingLeft = true;
        e.stopPropagation();
      });

      rightResizeHandle.addEventListener("mousedown", (e) => {
        isResizingRight = true;
        e.stopPropagation();
      });

      document.addEventListener("mousemove", (e) => {
        if (isResizingLeft) {
          let newWidth =
            taskBar.offsetWidth -
            (e.clientX - taskBar.getBoundingClientRect().left);
          newWidth = Math.max(50, newWidth);
          const widthDifference = taskBar.offsetWidth - newWidth;
          taskBar.style.width = `${newWidth}px`;
          taskBar.style.left = `${taskBar.offsetLeft + widthDifference}px`;
        }

        if (isResizingRight) {
          let newWidth = e.clientX - taskBar.getBoundingClientRect().left;
          newWidth = Math.max(50, newWidth);
          taskBar.style.width = `${newWidth}px`;
        }
      });

      document.addEventListener("mouseup", () => {
        isResizingLeft = false;
        isResizingRight = false;
      });

      // Добавляем ручки к полоске
      taskBar.appendChild(leftResizeHandle);
      taskBar.appendChild(rightResizeHandle);

      taskElement.appendChild(taskBar);

      container.appendChild(taskElement);
    });
  };

  useEffect(() => {
    createGanttChart();
  }, [tasks]);

  return (
    <div>
      <div
        className="gantt-chart"
        ref={containerRef}
        id="gantChartContainer"
      ></div>
      <div id="timeline"></div>
      <div id="tasks"></div>
    </div>
  );
};

export default GanttChart;



