import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Grid, Typography, CircularProgress } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fetchAuditYears, fetchAuditsByYear } from '../store/auditSlice';
import GeneratedAudits from '../components/generatedAudits/generatedAudits';
import AuditTable from '../components/auditTable/auditTable';
import { readDraftAuditsByYear } from '../store/draftAuditSlice';

const PlanPage = () => {
    const dispatch = useDispatch();
    const audits = useSelector((state) => state.audits.audits);
    const auditStatus = useSelector((state) => state.audits.status);
    const error = useSelector((state) => state.audits.error);
    const [calendarYear, setCalendarYear] = useState(null);

    useEffect(() => {
        dispatch(fetchAuditYears());
    }, [dispatch]);

    useEffect(() => {
        if (calendarYear) {
            dispatch(fetchAuditsByYear(calendarYear));
            dispatch(readDraftAuditsByYear(calendarYear));
        }
    }, [calendarYear, dispatch]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container style={{ padding: '1rem' }}>
                <Typography variant="h4" gutterBottom style={{ marginTop: '2rem' }}>
                    Выберите год
                </Typography>
                <Grid container spacing={3} style={{ marginBottom: '2rem' }}>
                    <Grid item xs={12} md={4} style={{ maxWidth: '250px' }}>
                        <DesktopDatePicker
                            views={['year']}
                            value={calendarYear ? new Date(calendarYear, 0) : null}
                            onChange={(newValue) => {
                                if (newValue) setCalendarYear(newValue.getFullYear());
                                else setCalendarYear(null);
                            }}
                        />
                    </Grid>
                </Grid>

                <Typography variant="h4" gutterBottom style={{ marginTop: '2rem' }}>
                    Аудиты для согласования
                </Typography>
                {auditStatus === 'loading' && (
                    <Grid container justifyContent="center" style={{ marginTop: '2rem' }}>
                        <CircularProgress />
                    </Grid>
                )}

                {!calendarYear && auditStatus !== 'loading' && (
                    <Typography variant="h15" style={{ marginTop: '10px' }}>
                        Пожалуйста, выберите год для отображения аудитов.
                    </Typography>
                )}

                {calendarYear && auditStatus === 'succeeded' && audits.length === 0 && (
                    <Typography variant="h15" style={{ marginTop: '10px' }}>
                        В выбранном году аудиты отсутствуют.
                    </Typography>
                )}

                {calendarYear && auditStatus === 'succeeded' && audits.length > 0 && (
                    <AuditTable data={audits} />
                )}

                <Typography variant="h4" gutterBottom style={{ marginTop: '2rem' }}>
                    Сгенерировать план аудитов в ИИ
                </Typography>
                <GeneratedAudits year={calendarYear} />

                {auditStatus === 'error' && (
                    <Grid item xs={12} style={{ marginTop: '1rem', color: 'red' }}>
                        <p>Error: {error}</p>
                    </Grid>
                )}
            </Container>
        </LocalizationProvider>
    );
};

export default PlanPage;
