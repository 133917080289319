// src/pages/resourcesPage.js

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuditorList from "../components/auditorList";
import { fetchAuditsByYear } from "../store/auditSlice";
import GanttChart from "../components/ganttChart/ganttChart";
import BasicButtons from '../components/monthChartGant/monthChartGant';

const ResourcesPage = () => {
  const dispatch = useDispatch();
  const transformedUsers = useSelector((state) => state.users.transformedUsers);
  const currentYear = new Date().getFullYear();
  const audits = useSelector((state) => state.audits.audits);

  console.log("audits", audits);

  useEffect(() => {
    dispatch(fetchAuditsByYear(currentYear));
  }, [dispatch, currentYear]);




  return (
    <div className="d-flex spaces-x-80">
      <div>
        <BasicButtons />
        <GanttChart audits={audits} />
        </div>
      <AuditorList title="Список аудиторов" auditorList={transformedUsers} />
    </div>
  );
};

export default ResourcesPage;
