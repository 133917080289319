import Chat from '../components/chat/chat';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessage } from '../store/chatSlice';

const ChatGPTPage = () => {
    const dispatch = useDispatch();
    const chat = useSelector((state) => state.chat);

    const [prompt, setPrompt] = useState('');

    const handleEmptyPromptSubmit = () => {
        if (prompt.trim()) {
            dispatch(
                sendMessage({
                    message: prompt,
                    prompt: 'EMPTY',
                })
            );
            setPrompt('');
        }
    };





    return (
        <>
            <h1 className='main-title'>GPT:</h1>
            <Chat
                prompt={prompt}
                onPromptChange={setPrompt}
                additionalButtons={
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleEmptyPromptSubmit}
                        >
                            Send
                        </Button>
                    </>
                }
            />
        </>
    );
};

export default ChatGPTPage;
