import { Button, MenuItem, Select, Box, CircularProgress, Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fetchAuditsByYear, generateAuditProgram } from '../store/auditSlice';
import TeamList from '../components/teamList';
import ProjectSchedule from '../components/projectSchedule';
import mockProjectScheduleData from '../components/projectSchedule/mockProjectSchedule';
import AuditProgramTable from '../components/auditProgramTable/auditProgramTable';

const AuditPage = () => {
    const dispatch = useDispatch();
    const audits = useSelector((state) => state.audits.audits); // Данные из store > audits > audits
    const auditStatus = useSelector((state) => state.audits.status);
    const error = useSelector((state) => state.audits.error);
    const [calendarYear, setCalendarYear] = useState(null);
    const [selectedAudit, setSelectedAudit] = useState(null); // Хранение объекта аудита
    const [teamListData, setTeamListData] = useState([]); // Данные для TeamList
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    useEffect(() => {
        if (calendarYear) {
            dispatch(fetchAuditsByYear(calendarYear));
        }
    }, [calendarYear, dispatch]);

    console.log("teamListData", teamListData)

    useEffect(() => {
        if (selectedAudit) {
            const curator = selectedAudit.curator;

            const teamList = [
                {
                    avatarUrl:
                        'https://img.freepik.com/free-photo/close-up-on-adorable-kitten-indoors_23-2150782471.jpg?w=740&t=st=1715798283~exp=1715798883~hmac=b76b283e598b43180be68b455509c1e43086a55c23322e918ad754ca09e1ad25',
                    name: curator
                        ? `${curator.first_name} ${curator.last_name}`
                        : 'Нет куратора',
                    jobPosition: 'Куратор',
                },
            ];

            setTeamListData(teamList);
        }
    }, [selectedAudit]);

    const handleGenerateProgram = async () => {
        if (selectedAudit) {
            await dispatch(generateAuditProgram(selectedAudit.id));
            setRefreshTrigger((prev) => !prev); // Меняем значение триггера
        }
    };

    const handleAuditChange = (event) => {
        const auditId = event.target.value; // Получаем ID выбранного аудита
        const audit = audits.find((a) => a.id === auditId); // Находим объект аудита по ID
        setSelectedAudit(audit); // Сохраняем объект
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Typography variant="h4" gutterBottom sx={{ marginTop: '2rem' }}>
                Выберите год
            </Typography>
            <Grid container spacing={3} sx={{ marginBottom: '2rem' }}>
                <Grid item xs={12} md={4} sx={{ maxWidth: '250px' }}>
                    <DesktopDatePicker
                        views={['year']}
                        value={calendarYear ? new Date(calendarYear, 0) : null}
                        onChange={(newValue) => {
                            if (newValue) setCalendarYear(newValue.getFullYear());
                            else setCalendarYear(null);
                        }}
                    />
                </Grid>
            </Grid>

            {auditStatus === 'loading' && (
                <Grid container justifyContent="center" sx={{ marginTop: '2rem' }}>
                    <CircularProgress />
                </Grid>
            )}

            {!calendarYear && auditStatus !== 'loading' && (
                <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                    Пожалуйста, выберите год для отображения аудитов.
                </Typography>
            )}

            {calendarYear && auditStatus === 'succeeded' && audits.length === 0 && (
                <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                    В выбранном году аудиты отсутствуют.
                </Typography>
            )}

            {calendarYear && auditStatus === 'succeeded' && audits.length > 0 && (
                <>
                    <Select
                        value={selectedAudit ? selectedAudit.id : ''}
                        onChange={handleAuditChange}
                        displayEmpty
                        sx={{ marginRight: '10px', marginTop: '1rem' }}
                    >
                        <MenuItem value="" disabled>
                            Выберите аудит
                        </MenuItem>
                        {audits.map((audit) => (
                            <MenuItem key={audit.id} value={audit.id}>
                                {audit.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGenerateProgram}
                        sx={{ marginTop: '1rem' }}
                    >
                        Generate Program
                    </Button>
                </>
            )}

            {selectedAudit && (
                <Box sx={{ marginTop: '2rem' }}>
                    <AuditProgramTable auditId={selectedAudit.id} refreshTrigger={refreshTrigger} />
                </Box>
            )}

            <Box sx={{ marginTop: '2rem' }}>
                <TeamList title="Команда" list={teamListData} />
            </Box>
            <Box sx={{ marginTop: '2rem' }}>
                <ProjectSchedule title="График проекта" shedule={mockProjectScheduleData} />
            </Box>

            {auditStatus === 'failed' && (
                <Typography variant="body1" sx={{ marginTop: '1rem', color: 'red' }}>
                    Error: {error}
                </Typography>
            )}
        </LocalizationProvider>
    );
};

export default AuditPage;
