import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import auditReducer from './auditSlice';
import userReducer from './userSlice';
import chatReducer from './chatSlice';
import draftAuditsReducer from './draftAuditSlice';

export const store = configureStore({
    reducer: {
        authorization: authReducer,
        audits: auditReducer,
        users: userReducer,
        chat: chatReducer,
        draftAudits: draftAuditsReducer,
    },
});
