import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

export default function BasicButtons() {
  return (
    <Stack spacing={0.5} direction="row" sx={{ marginTop: '20px', }}>
      <Button variant="contained" sx={{ border: "2px solid white", width: 130 }}>Январь</Button>
      <Button variant="contained" sx={{ border: "2px solid white", width: 130}}>Февраль</Button>
      <Button variant="contained" sx={{ border: "2px solid white", width: 130}}>Март</Button>
      <Button variant="contained" sx={{ border: "2px solid white", width: 130}}>Апрель</Button>
      <Button variant="contained" sx={{ border: "2px solid white", width: 130}}>Май</Button>
      <Button variant="contained" sx={{ border: "2px solid white", width: 130}}>Июнь</Button>
    </Stack>
  );
}