import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { createGPTPlan, readDraftAuditsByYear, deleteDraftAudit, updateDraftAudit } from '../../store/draftAuditSlice';
import { createAudit, fetchAuditsByYear } from '../../store/auditSlice';

const GeneratedAudits = ({ year }) => {
    const [quantity, setQuantity] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const dispatch = useDispatch();
    const { audits: draftAudits, status, error } = useSelector((state) => state.draftAudits);

    useEffect(() => {
        dispatch(readDraftAuditsByYear(year));

    }, [year, dispatch]);

    const handleCreatePlan = () => {
        const planData = {
            audits_amount: quantity,
            year: year,
        };
        dispatch(createGPTPlan(planData));
        dispatch(readDraftAuditsByYear(year));
    };

    const handleDelete = (id) => {
        dispatch(deleteDraftAudit(id));
    };

    const handleDeleteSelected = () => {
        selectedRows.forEach((id) => dispatch(deleteDraftAudit(id)));
        setSelectedRows([]);
    };

    const handleEditCellChange = async (params) => {
        const { id, field, value } = params;
        if (field === 'auditName') {
            // Dispatch action to update the audit name
            await dispatch(updateDraftAudit({ id, changes: { auditName: value } }));
        }
    };

    const handleAddToAudits = async (audit, id) => {
        const currentDate = new Date().toISOString().split('T')[0];
        const auditData = {
            name: audit.audit_name,
            start_date: currentDate,
            end_date: currentDate,
            year: year,
        };
        try {
            await dispatch(createAudit(auditData)).unwrap();
            await dispatch(deleteDraftAudit(id)).unwrap();
            await dispatch(fetchAuditsByYear(year)).unwrap();
        } catch (error) {
            console.error('Ошибка при добавлении или удалении аудита:', error);
        }
    };

    const columns = [
        {
            field: 'actions',
            headerName: 'Действия',
            width: 100,
            renderCell: (params) => (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleAddToAudits(params.row, params.row.id)}
                    >
                        Добавить в аудиты
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => handleDelete(params.row.id)}
                        sx={{
                            borderColor: 'red',
                            color: 'red',
                            '&:hover': {
                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                borderColor: 'darkred',
                            },
                        }}
                    >
                        Удалить
                    </Button>
                </div>
            ),
        },
        {
            field: 'audit_type',
            headerName: 'Тип аудита',
            width: 115,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: 'audit_name',
            headerName: 'Название аудита',
            width: 250,
            editable: true,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: 'key_risk',
            headerName: 'Ключевой риск',
            width: 250,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: 'risk_factors',
            headerName: 'Факторы риска',
            width: 250,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: 'audit_directions',
            headerName: 'Направления аудита',
            width: 250,
            renderCell: (params) => (
                <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                    {params.value}
                </div>
            ),
        },
    ];

    return (
        <>
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12} md={4} style={{ maxWidth: '250px' }}>
                    <FormControl fullWidth>
                        <InputLabel id="quantity-select-label">Выберите количество</InputLabel>
                        <Select
                            labelId="quantity-select-label"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                        >
                            {[...Array(10).keys()].map((num) => (
                                <MenuItem key={num + 1} value={num + 1}>
                                    {num + 1}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'stretch' }}>
                    <Button
                        variant="contained"
                        color={status === 'loading' ? 'secondary' : 'primary'}
                        onClick={handleCreatePlan}
                        fullWidth
                        disabled={status === 'loading'}
                    >
                        {status === 'loading' ? 'Загрузка...' : 'Create Plan'}
                    </Button>
                </Grid>

                <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'stretch' }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDeleteSelected}
                        fullWidth
                        disabled={selectedRows.length === 0}
                    >
                        Удалить выбранные
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                    {status === 'loading' && (
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <CircularProgress />
                            <Typography variant="body1" style={{ marginTop: '10px' }}>
                                Загрузка данных...
                            </Typography>
                        </div>
                    )}
                    {status === 'failed' && (
                        <Typography variant="body1" color="error" style={{ textAlign: 'center', marginTop: '20px' }}>
                            Произошла ошибка при загрузке данных: {error}
                        </Typography>
                    )}
                    {year && (
                        <DataGrid
                            rows={draftAudits}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            autoHeight
                            checkboxSelection
                            getRowHeight={() => 'auto'} // Автоматическая высота строк
                            onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
                            onCellEditCommit={handleEditCellChange}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default GeneratedAudits;
