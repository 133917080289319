import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL_PLANNING, fetchWithAuth, getHeaders } from '../api/utils';

// Async Thunks
export const createDraftAudit = createAsyncThunk(
  'draftAudits/createDraftAudit',
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetchWithAuth(`${BASE_URL_PLANNING}/api/draft-audits/`, {
        method: 'POST',
        headers: getHeaders(true),
        body: JSON.stringify(data),
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.message || 'Unknown error occurred');
    }
  }
);

export const deleteDraftAudit = createAsyncThunk(
  'draftAudits/deleteDraftAudit',
  async (id, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line
      const response = await fetchWithAuth(`${BASE_URL_PLANNING}/api/draft-audits/${id}/delete/`, {
        method: 'DELETE',
        headers: getHeaders(true),
      });
      return { id };
    } catch (error) {
      return rejectWithValue(error.message || 'Unknown error occurred');
    }
  }
);

export const updateDraftAudit = createAsyncThunk(
  'draftAudits/updateDraftAudit',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await fetchWithAuth(`${BASE_URL_PLANNING}/api/draft-audits/${id}/update/`, {
        method: 'PATCH',
        headers: getHeaders(true),
        body: JSON.stringify(data),
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.message || 'Unknown error occurred');
    }
  }
);

export const readDraftAuditsByYear = createAsyncThunk(
  'draftAudits/readDraftAuditsByYear',
  async (year, { rejectWithValue }) => {
    try {
      const response = await fetchWithAuth(`${BASE_URL_PLANNING}/api/draft-audits/${year}/`, {
        method: 'GET',
        headers: getHeaders(true),
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.message || 'Unknown error occurred');
    }
  }
);

export const createGPTPlan = createAsyncThunk(
  'draftAudits/createGPTPlan',
  async (data, { rejectWithValue }) => {
    try {
      const response = await fetchWithAuth(`${BASE_URL_PLANNING}/api/gpt/create_plan/`, {
        method: 'POST',
        headers: getHeaders(true),
        body: JSON.stringify(data),
      });
      return response;
    } catch (error) {
      return rejectWithValue(error.message || 'Unknown error occurred');
    }
  }
);

// Slice
const draftAuditsSlice = createSlice({
    name: 'draftAudits',
    initialState: {
      audits: [],
      status: 'idle',
      error: null,
      gptPlan: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(createDraftAudit.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(createDraftAudit.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.audits.push(action.payload); // Здесь изменяем на audits
        })
        .addCase(createDraftAudit.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        })
        .addCase(deleteDraftAudit.fulfilled, (state, action) => {
          state.audits = state.audits.filter((item) => item.id !== action.payload.id);
        })
        .addCase(updateDraftAudit.fulfilled, (state, action) => {
          const index = state.audits.findIndex((item) => item.id === action.payload.id);
          if (index !== -1) {
            state.audits[index] = { ...state.audits[index], ...action.payload };
          }
        })
        .addCase(readDraftAuditsByYear.fulfilled, (state, action) => {
          state.audits = action.payload;
        })
        .addCase(createGPTPlan.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(createGPTPlan.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.audits = action.payload;
        })
        .addCase(createGPTPlan.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload;
        });
    },
  });  

export default draftAuditsSlice.reducer;
